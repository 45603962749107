html, body {
  height: 100vh;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  color: #171717;
  background-color: #e6e6e6;
  flex-direction: column;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  display: flex;
}

a:link, a:visited {
  color: #0163c7;
  text-decoration-skip-ink: auto;
  transition: color .15s ease-in-out;
}

a:hover, a:active {
  color: #8e00f1;
}

::selection {
  background: #d0f3;
}

h1 {
  margin: 16px;
  font-weight: 100;
}

#intro {
  margin: 4px 0 16px;
  padding: 8px;
  font-size: 16px;
  font-weight: 300;
}

@media (min-width: 1024px) {
  #intro {
    margin: 4px 0 32px;
    padding: 16px;
    font-size: 18px;
  }
}

#intro sup {
  vertical-align: text-top;
}

#intro sup a:link, #intro sup a:visited {
  text-decoration: none;
}

#app {
  flex-direction: column;
  flex: 1 0 100vh;
  align-items: center;
  display: flex;
}

#app .loading-spinner {
  opacity: 0;
  transition: all .3s ease-in-out;
}

.loading #app .loading-spinner {
  opacity: 1;
}

.script h3 small {
  font-size: 1rem;
  line-height: 19px;
}

#caveats {
  width: 300px;
  border-top: 1px solid #d5d5d5;
  margin-top: 32px;
  padding: 8px 16px;
}

@media (min-width: 1024px) {
  #caveats {
    width: 650px;
    margin-bottom: 0;
    padding: 16px 0;
  }
}

#caveats h2 {
  font-size: 24px;
  font-weight: 100;
}

#caveats .back a {
  align-items: center;
  column-gap: 4px;
  display: flex;
}

#caveats .back a:after {
  content: "";
  width: 16px;
  height: 16px;
  opacity: .666;
  background: url("arrow_upward.e8e13fa3.svg") 0 0 / 16px;
  display: block;
}

footer {
  width: 300px;
  color: #555;
  border-top: 1px solid #d5d5d5;
  padding: 8px 16px;
  font-size: 14px;
}

@media (min-width: 1024px) {
  footer {
    width: 650px;
    padding: 16px 0;
  }
}

footer ul {
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

footer li {
  margin-bottom: 4px;
}

#script___inception, .inception .script[id^="script"] {
  display: none;
}

.inception #script___inception {
  display: block;
}

@media (min-width: 1024px) {
  .inception #script___inception {
    display: flex;
  }
}

.party {
  background-image: url("loading.2fbdfc06.svg");
  background-position: top;
  background-size: 128px;
  background-attachment: fixed;
}

.party > * {
  backdrop-filter: blur(5px);
  background-color: #e6e6e633;
  border-radius: 8px;
  box-shadow: 0 4px 30px #1717171a;
}

form[data-v-8fa0c3] {
  width: 300px;
  margin-bottom: 16px;
  padding: 0 16px;
}

@media (min-width: 1024px) {
  form[data-v-8fa0c3] {
    width: 650px;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 0;
    display: flex;
  }
}

.results-heading[data-v-8fa0c3] {
  font-size: 24px;
  font-weight: 100;
}

.next-update[data-v-8fa0c3] {
  flex-grow: 1;
  margin-bottom: 24px;
  font-size: 14px;
}

@media (min-width: 1024px) {
  .next-update[data-v-8fa0c3] {
    margin-bottom: 6px;
  }
}

.filter[data-v-8fa0c3] {
  position: relative;
}

.filter label[data-v-8fa0c3] {
  font-size: 12px;
  font-weight: 300;
  transition: all .15s ease-in-out;
  position: absolute;
  top: -16px;
  left: 0;
}

.filter input[data-v-8fa0c3] {
  width: 100%;
  background-color: #d5d5d580;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 4px 4px 4px 32px;
}

@media (min-width: 1024px) {
  .filter input[data-v-8fa0c3] {
    width: 14rem;
  }
}

.filter input[data-v-8fa0c3]:focus-visible {
  border-color: #555;
  outline: none;
  box-shadow: 0 0 4px 4px #0074d94d;
}

.filter input:placeholder-shown + label[data-v-8fa0c3] {
  font-size: 14px;
  top: 4px;
  left: 32px;
}

.filter input:focus-visible + label[data-v-8fa0c3] {
  font-size: 12px;
  top: -16px;
  left: 0;
}

.filter[data-v-8fa0c3]:before {
  content: "";
  width: 24px;
  height: 24px;
  opacity: .2;
  background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M15.5%2014h-.79l-.28-.27a6.5%206.5%200%200%200%201.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505%206.505%200%200%200-7.27%207.27c.34%202.8%202.56%205.12%205.34%205.59a6.5%206.5%200%200%200%205.34-1.48l.27.28v.79l4.25%204.25c.41.41%201.08.41%201.49%200%20.41-.41.41-1.08%200-1.49L15.5%2014zm-6%200C7.01%2014%205%2011.99%205%209.5S7.01%205%209.5%205%2014%207.01%2014%209.5%2011.99%2014%209.5%2014z%22%2F%3E%3C%2Fsvg%3E");
  display: block;
  position: absolute;
  top: 1px;
  left: 4px;
}

.script[data-v-80bad9] {
  width: 300px;
  --index: 29;
  border-radius: 8px;
  padding: 16px;
  transition: all .15s ease-in-out;
}

@media (min-width: 1024px) {
  .script[data-v-80bad9] {
    width: 650px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }

  .script h3[data-v-80bad9] {
    margin-bottom: 0;
  }
}

.script.hidden[data-v-80bad9] {
  display: none;
}

.script[data-v-80bad9]:hover {
  background-color: #f8f8f833;
  box-shadow: 0 4px 30px #1717171a;
}

.script[data-v-80bad9]:target, .script.target[data-v-80bad9] {
  background-color: #dd00ff0d;
  box-shadow: 0 4px 30px #1717171a;
}

.script.pinned .chart-wrapper[data-v-80bad9]:after {
  opacity: .5;
  top: 0;
}

@media (min-width: 1024px) {
  .script.pinned .chart-wrapper[data-v-80bad9]:after {
    top: -4px;
  }
}

.chart-wrapper[data-v-80bad9] {
  position: relative;
}

.chart-wrapper[data-v-80bad9]:after {
  content: "";
  top: -4px;
  left: calc(var(--index) * 10px - 7px);
  width: 16px;
  height: 16px;
  opacity: .3;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M14%204v5c0%201.12.37%202.16%201%203H9c.65-.86%201-1.9%201-3V4h4m3-2H7c-.55%200-1%20.45-1%201s.45%201%201%201h1v5c0%201.66-1.34%203-3%203v2h5.97v7l1%201%201-1v-7H19v-2c-1.66%200-3-1.34-3-3V4h1c.55%200%201-.45%201-1s-.45-1-1-1z%22%2F%3E%3C%2Fsvg%3E");
  background-size: 16px;
  transition: top .15s ease-in-out, opacity .3s ease-in-out;
  display: block;
  position: absolute;
}

@media (min-width: 1024px) {
  .chart-wrapper[data-v-80bad9]:after {
    top: -8px;
    left: calc(var(--index) * 13.3667px - 8px);
  }
}

h3[data-v-80bad9] {
  flex: 1 0 100%;
  margin: 0 0 8px;
  font-size: 22px;
  font-weight: 100;
  line-height: 24px;
}

h3 a[data-v-80bad9]:link, h3 a[data-v-80bad9]:visited {
  color: #171717;
  align-items: flex-end;
  column-gap: 4px;
  text-decoration: none;
  display: flex;
  position: relative;
}

h3 a[data-v-80bad9]:link:after, h3 a[data-v-80bad9]:visited:after {
  content: "";
  width: 24px;
  height: 24px;
  opacity: 0;
  background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M17%207h-3c-.55%200-1%20.45-1%201s.45%201%201%201h3c1.65%200%203%201.35%203%203s-1.35%203-3%203h-3c-.55%200-1%20.45-1%201s.45%201%201%201h3c2.76%200%205-2.24%205-5s-2.24-5-5-5zm-9%205c0%20.55.45%201%201%201h6c.55%200%201-.45%201-1s-.45-1-1-1H9c-.55%200-1%20.45-1%201zm2%203H7c-1.65%200-3-1.35-3-3s1.35-3%203-3h3c.55%200%201-.45%201-1s-.45-1-1-1H7c-2.76%200-5%202.24-5%205s2.24%205%205%205h3c.55%200%201-.45%201-1s-.45-1-1-1z%22%2F%3E%3C%2Fsvg%3E");
  transition: opacity .3s ease-in-out;
  display: block;
}

h3 a[data-v-80bad9]:hover, h3 a[data-v-80bad9]:active, h3 a[data-v-80bad9]:focus {
  color: #171717;
  outline: none;
  text-decoration: underline 1px;
}

h3 a[data-v-80bad9]:hover:after, h3 a[data-v-80bad9]:active:after, h3 a[data-v-80bad9]:focus:after {
  opacity: .3;
}

.metadata[data-v-80bad9] {
  margin: 8px 0 0;
}

@media (min-width: 1024px) {
  .metadata[data-v-80bad9] {
    width: calc(100% - 416px);
  }
}

p[data-v-80bad9], details[data-v-80bad9] {
  margin: 0 0 4px;
  font-weight: 300;
}

p strong[data-v-80bad9], details strong[data-v-80bad9] {
  font-weight: 400;
}

p summary[data-v-80bad9], details summary[data-v-80bad9] {
  cursor: default;
}

p summary[data-v-80bad9]::marker, p summary[data-v-80bad9]::-webkit-details-marker, details summary[data-v-80bad9]::marker, details summary[data-v-80bad9]::-webkit-details-marker {
  content: "";
  display: none;
}

p summary[data-v-80bad9]:after, details summary[data-v-80bad9]:after {
  content: "";
  width: 18px;
  height: 18px;
  float: right;
  transform-origin: 9px;
  background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M15.88%209.29%2012%2013.17%208.12%209.29a.996.996%200%201%200-1.41%201.41l4.59%204.59c.39.39%201.02.39%201.41%200l4.59-4.59a.996.996%200%200%200%200-1.41c-.39-.38-1.03-.39-1.42%200z%22%2F%3E%3C%2Fsvg%3E") 0 0 / 18px;
  transition: transform .15s ease-in-out;
  display: block;
}

p[open] summary[data-v-80bad9], details[open] summary[data-v-80bad9] {
  margin-bottom: 8px;
}

p[open] summary[data-v-80bad9]:after, details[open] summary[data-v-80bad9]:after {
  transform: rotate(-180deg);
}

p .trend[data-v-80bad9], p .subresources-trend[data-v-80bad9], details .trend[data-v-80bad9], details .subresources-trend[data-v-80bad9] {
  width: 16px;
  height: 16px;
  background-size: 16px;
  margin-bottom: -2px;
  margin-left: 4px;
  display: inline-block;
}

p .trend.down[data-v-80bad9], p .subresources-trend.down[data-v-80bad9], details .trend.down[data-v-80bad9], details .subresources-trend.down[data-v-80bad9] {
  filter: invert(63%) sepia(47%) saturate(583%) hue-rotate(71deg) brightness(98%) contrast(86%);
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22m16.85%2017.15%201.44-1.44-4.88-4.88-3.29%203.29a.996.996%200%200%201-1.41%200l-6-6.01A.996.996%200%201%201%204.12%206.7L9.41%2012l3.29-3.29a.996.996%200%200%201%201.41%200l5.59%205.58%201.44-1.44a.5.5%200%200%201%20.85.35v4.29c0%20.28-.22.5-.5.5H17.2c-.44.01-.66-.53-.35-.84z%22%2F%3E%3C%2Fsvg%3E");
}

p .trend.flat[data-v-80bad9], p .subresources-trend.flat[data-v-80bad9], details .trend.flat[data-v-80bad9], details .subresources-trend.flat[data-v-80bad9] {
  filter: invert(54%) sepia(0%) saturate(0%) hue-rotate(196deg) brightness(99%) contrast(88%);
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22m21.65%2011.65-2.79-2.79a.501.501%200%200%200-.86.35V11H4c-.55%200-1%20.45-1%201s.45%201%201%201h14v1.79c0%20.45.54.67.85.35l2.79-2.79c.2-.19.2-.51.01-.7z%22%2F%3E%3C%2Fsvg%3E");
}

p .trend.up[data-v-80bad9], p .subresources-trend.up[data-v-80bad9], details .trend.up[data-v-80bad9], details .subresources-trend.up[data-v-80bad9] {
  filter: invert(42%) sepia(36%) saturate(949%) hue-rotate(314deg) brightness(96%) contrast(83%);
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22m16.85%206.85%201.44%201.44-4.88%204.88-3.29-3.29a.996.996%200%200%200-1.41%200l-6%206.01a.996.996%200%201%200%201.41%201.41L9.41%2012l3.29%203.29c.39.39%201.02.39%201.41%200l5.59-5.58%201.44%201.44a.5.5%200%200%200%20.85-.35V6.5a.48.48%200%200%200-.49-.5h-4.29a.5.5%200%200%200-.36.85z%22%2F%3E%3C%2Fsvg%3E");
}

p pre[data-v-80bad9], details pre[data-v-80bad9] {
  width: 100%;
  max-width: 288px;
  max-height: calc(3rem - 2px);
  scrollbar-width: thin;
  background-color: #e6e6e6;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  margin-top: 4px;
  padding: 4px;
  overflow: auto;
}

p pre[data-v-80bad9]::-webkit-scrollbar, details pre[data-v-80bad9]::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

p pre[data-v-80bad9]::-webkit-scrollbar-thumb, details pre[data-v-80bad9]::-webkit-scrollbar-thumb {
  background: #5555554d;
  border-radius: 2px;
}

p pre[data-v-80bad9]::-webkit-scrollbar-corner, details pre[data-v-80bad9]::-webkit-scrollbar-corner {
  background: none;
}

@media (min-width: 1024px) {
  p pre[data-v-80bad9], details pre[data-v-80bad9] {
    max-width: 224px;
  }
}

p .show-data-uris[data-v-80bad9], details .show-data-uris[data-v-80bad9] {
  align-items: center;
  column-gap: 4px;
  margin: 0 0 4px;
  font-size: 14px;
  display: flex;
}

p .show-data-uris input[data-v-80bad9], details .show-data-uris input[data-v-80bad9] {
  margin: 0;
}

p table[data-v-80bad9], details table[data-v-80bad9] {
  width: 100%;
  border-spacing: 0;
}

p table thead th[data-v-80bad9], details table thead th[data-v-80bad9] {
  text-align: left;
  padding-right: 8px;
  font-size: 14px;
  font-weight: 500;
}

p table thead th[data-v-80bad9]:last-of-type, details table thead th[data-v-80bad9]:last-of-type {
  padding-right: 0;
}

p table tbody th[data-v-80bad9], details table tbody th[data-v-80bad9] {
  font-weight: normal;
}

p table tbody td[data-v-80bad9], details table tbody td[data-v-80bad9] {
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 8px;
  font-size: 14px;
  overflow: hidden;
}

p table tbody td[data-v-80bad9]:last-of-type, details table tbody td[data-v-80bad9]:last-of-type {
  padding-right: 0;
}

p table tbody pre[data-v-80bad9], details table tbody pre[data-v-80bad9] {
  margin: 4px 0 0;
}

.date-range[data-v-80bad9] {
  width: 100%;
  justify-content: space-between;
  margin: 0;
  font-size: 10px;
  display: flex;
}

.chart-wrapper[data-v-80bad9] {
  width: 300px;
}

@media (min-width: 1024px) {
  .chart-wrapper[data-v-80bad9] {
    width: 400px;
  }
}

.chart[data-v-80bad9] {
  width: 300px;
  height: 104px;
  cursor: crosshair;
  border-bottom: 1px solid #d5d5d5;
  border-left: 1px solid #d5d5d5;
  margin-top: 12px;
}

@media (min-width: 1024px) {
  .chart[data-v-80bad9] {
    width: 400px;
    height: 139px;
    margin-top: 8px;
  }
}

form[data-v-8fa0c3] {
  width: 300px;
  margin-bottom: 16px;
  padding: 0 16px;
}

@media (min-width: 1024px) {
  form[data-v-8fa0c3] {
    width: 650px;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 0;
    display: flex;
  }
}

.results-heading[data-v-8fa0c3] {
  font-size: 24px;
  font-weight: 100;
}

.next-update[data-v-8fa0c3] {
  flex-grow: 1;
  margin-bottom: 24px;
  font-size: 14px;
}

@media (min-width: 1024px) {
  .next-update[data-v-8fa0c3] {
    margin-bottom: 6px;
  }
}

.filter[data-v-8fa0c3] {
  position: relative;
}

.filter label[data-v-8fa0c3] {
  font-size: 12px;
  font-weight: 300;
  transition: all .15s ease-in-out;
  position: absolute;
  top: -16px;
  left: 0;
}

.filter input[data-v-8fa0c3] {
  width: 100%;
  background-color: #d5d5d580;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 4px 4px 4px 32px;
}

@media (min-width: 1024px) {
  .filter input[data-v-8fa0c3] {
    width: 14rem;
  }
}

.filter input[data-v-8fa0c3]:focus-visible {
  border-color: #555;
  outline: none;
  box-shadow: 0 0 4px 4px #0074d94d;
}

.filter input:placeholder-shown + label[data-v-8fa0c3] {
  font-size: 14px;
  top: 4px;
  left: 32px;
}

.filter input:focus-visible + label[data-v-8fa0c3] {
  font-size: 12px;
  top: -16px;
  left: 0;
}

.filter[data-v-8fa0c3]:before {
  content: "";
  width: 24px;
  height: 24px;
  opacity: .2;
  background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M15.5%2014h-.79l-.28-.27a6.5%206.5%200%200%200%201.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505%206.505%200%200%200-7.27%207.27c.34%202.8%202.56%205.12%205.34%205.59a6.5%206.5%200%200%200%205.34-1.48l.27.28v.79l4.25%204.25c.41.41%201.08.41%201.49%200%20.41-.41.41-1.08%200-1.49L15.5%2014zm-6%200C7.01%2014%205%2011.99%205%209.5S7.01%205%209.5%205%2014%207.01%2014%209.5%2011.99%2014%209.5%2014z%22%2F%3E%3C%2Fsvg%3E");
  display: block;
  position: absolute;
  top: 1px;
  left: 4px;
}

.script[data-v-80bad9] {
  width: 300px;
  --index: 29;
  border-radius: 8px;
  padding: 16px;
  transition: all .15s ease-in-out;
}

@media (min-width: 1024px) {
  .script[data-v-80bad9] {
    width: 650px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }

  .script h3[data-v-80bad9] {
    margin-bottom: 0;
  }
}

.script.hidden[data-v-80bad9] {
  display: none;
}

.script[data-v-80bad9]:hover {
  background-color: #f8f8f833;
  box-shadow: 0 4px 30px #1717171a;
}

.script[data-v-80bad9]:target, .script.target[data-v-80bad9] {
  background-color: #dd00ff0d;
  box-shadow: 0 4px 30px #1717171a;
}

.script.pinned .chart-wrapper[data-v-80bad9]:after {
  opacity: .5;
  top: 0;
}

@media (min-width: 1024px) {
  .script.pinned .chart-wrapper[data-v-80bad9]:after {
    top: -4px;
  }
}

.chart-wrapper[data-v-80bad9] {
  position: relative;
}

.chart-wrapper[data-v-80bad9]:after {
  content: "";
  top: -4px;
  left: calc(var(--index) * 10px - 7px);
  width: 16px;
  height: 16px;
  opacity: .3;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M14%204v5c0%201.12.37%202.16%201%203H9c.65-.86%201-1.9%201-3V4h4m3-2H7c-.55%200-1%20.45-1%201s.45%201%201%201h1v5c0%201.66-1.34%203-3%203v2h5.97v7l1%201%201-1v-7H19v-2c-1.66%200-3-1.34-3-3V4h1c.55%200%201-.45%201-1s-.45-1-1-1z%22%2F%3E%3C%2Fsvg%3E");
  background-size: 16px;
  transition: top .15s ease-in-out, opacity .3s ease-in-out;
  display: block;
  position: absolute;
}

@media (min-width: 1024px) {
  .chart-wrapper[data-v-80bad9]:after {
    top: -8px;
    left: calc(var(--index) * 13.3667px - 8px);
  }
}

h3[data-v-80bad9] {
  flex: 1 0 100%;
  margin: 0 0 8px;
  font-size: 22px;
  font-weight: 100;
  line-height: 24px;
}

h3 a[data-v-80bad9]:link, h3 a[data-v-80bad9]:visited {
  color: #171717;
  align-items: flex-end;
  column-gap: 4px;
  text-decoration: none;
  display: flex;
  position: relative;
}

h3 a[data-v-80bad9]:link:after, h3 a[data-v-80bad9]:visited:after {
  content: "";
  width: 24px;
  height: 24px;
  opacity: 0;
  background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M17%207h-3c-.55%200-1%20.45-1%201s.45%201%201%201h3c1.65%200%203%201.35%203%203s-1.35%203-3%203h-3c-.55%200-1%20.45-1%201s.45%201%201%201h3c2.76%200%205-2.24%205-5s-2.24-5-5-5zm-9%205c0%20.55.45%201%201%201h6c.55%200%201-.45%201-1s-.45-1-1-1H9c-.55%200-1%20.45-1%201zm2%203H7c-1.65%200-3-1.35-3-3s1.35-3%203-3h3c.55%200%201-.45%201-1s-.45-1-1-1H7c-2.76%200-5%202.24-5%205s2.24%205%205%205h3c.55%200%201-.45%201-1s-.45-1-1-1z%22%2F%3E%3C%2Fsvg%3E");
  transition: opacity .3s ease-in-out;
  display: block;
}

h3 a[data-v-80bad9]:hover, h3 a[data-v-80bad9]:active, h3 a[data-v-80bad9]:focus {
  color: #171717;
  outline: none;
  text-decoration: underline 1px;
}

h3 a[data-v-80bad9]:hover:after, h3 a[data-v-80bad9]:active:after, h3 a[data-v-80bad9]:focus:after {
  opacity: .3;
}

.metadata[data-v-80bad9] {
  margin: 8px 0 0;
}

@media (min-width: 1024px) {
  .metadata[data-v-80bad9] {
    width: calc(100% - 416px);
  }
}

p[data-v-80bad9], details[data-v-80bad9] {
  margin: 0 0 4px;
  font-weight: 300;
}

p strong[data-v-80bad9], details strong[data-v-80bad9] {
  font-weight: 400;
}

p summary[data-v-80bad9], details summary[data-v-80bad9] {
  cursor: default;
}

p summary[data-v-80bad9]::marker, p summary[data-v-80bad9]::-webkit-details-marker, details summary[data-v-80bad9]::marker, details summary[data-v-80bad9]::-webkit-details-marker {
  content: "";
  display: none;
}

p summary[data-v-80bad9]:after, details summary[data-v-80bad9]:after {
  content: "";
  width: 18px;
  height: 18px;
  float: right;
  transform-origin: 9px;
  background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M15.88%209.29%2012%2013.17%208.12%209.29a.996.996%200%201%200-1.41%201.41l4.59%204.59c.39.39%201.02.39%201.41%200l4.59-4.59a.996.996%200%200%200%200-1.41c-.39-.38-1.03-.39-1.42%200z%22%2F%3E%3C%2Fsvg%3E") 0 0 / 18px;
  transition: transform .15s ease-in-out;
  display: block;
}

p[open] summary[data-v-80bad9], details[open] summary[data-v-80bad9] {
  margin-bottom: 8px;
}

p[open] summary[data-v-80bad9]:after, details[open] summary[data-v-80bad9]:after {
  transform: rotate(-180deg);
}

p .trend[data-v-80bad9], p .subresources-trend[data-v-80bad9], details .trend[data-v-80bad9], details .subresources-trend[data-v-80bad9] {
  width: 16px;
  height: 16px;
  background-size: 16px;
  margin-bottom: -2px;
  margin-left: 4px;
  display: inline-block;
}

p .trend.down[data-v-80bad9], p .subresources-trend.down[data-v-80bad9], details .trend.down[data-v-80bad9], details .subresources-trend.down[data-v-80bad9] {
  filter: invert(63%) sepia(47%) saturate(583%) hue-rotate(71deg) brightness(98%) contrast(86%);
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22m16.85%2017.15%201.44-1.44-4.88-4.88-3.29%203.29a.996.996%200%200%201-1.41%200l-6-6.01A.996.996%200%201%201%204.12%206.7L9.41%2012l3.29-3.29a.996.996%200%200%201%201.41%200l5.59%205.58%201.44-1.44a.5.5%200%200%201%20.85.35v4.29c0%20.28-.22.5-.5.5H17.2c-.44.01-.66-.53-.35-.84z%22%2F%3E%3C%2Fsvg%3E");
}

p .trend.flat[data-v-80bad9], p .subresources-trend.flat[data-v-80bad9], details .trend.flat[data-v-80bad9], details .subresources-trend.flat[data-v-80bad9] {
  filter: invert(54%) sepia(0%) saturate(0%) hue-rotate(196deg) brightness(99%) contrast(88%);
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22m21.65%2011.65-2.79-2.79a.501.501%200%200%200-.86.35V11H4c-.55%200-1%20.45-1%201s.45%201%201%201h14v1.79c0%20.45.54.67.85.35l2.79-2.79c.2-.19.2-.51.01-.7z%22%2F%3E%3C%2Fsvg%3E");
}

p .trend.up[data-v-80bad9], p .subresources-trend.up[data-v-80bad9], details .trend.up[data-v-80bad9], details .subresources-trend.up[data-v-80bad9] {
  filter: invert(42%) sepia(36%) saturate(949%) hue-rotate(314deg) brightness(96%) contrast(83%);
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22m16.85%206.85%201.44%201.44-4.88%204.88-3.29-3.29a.996.996%200%200%200-1.41%200l-6%206.01a.996.996%200%201%200%201.41%201.41L9.41%2012l3.29%203.29c.39.39%201.02.39%201.41%200l5.59-5.58%201.44%201.44a.5.5%200%200%200%20.85-.35V6.5a.48.48%200%200%200-.49-.5h-4.29a.5.5%200%200%200-.36.85z%22%2F%3E%3C%2Fsvg%3E");
}

p pre[data-v-80bad9], details pre[data-v-80bad9] {
  width: 100%;
  max-width: 288px;
  max-height: calc(3rem - 2px);
  scrollbar-width: thin;
  background-color: #e6e6e6;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  margin-top: 4px;
  padding: 4px;
  overflow: auto;
}

p pre[data-v-80bad9]::-webkit-scrollbar, details pre[data-v-80bad9]::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

p pre[data-v-80bad9]::-webkit-scrollbar-thumb, details pre[data-v-80bad9]::-webkit-scrollbar-thumb {
  background: #5555554d;
  border-radius: 2px;
}

p pre[data-v-80bad9]::-webkit-scrollbar-corner, details pre[data-v-80bad9]::-webkit-scrollbar-corner {
  background: none;
}

@media (min-width: 1024px) {
  p pre[data-v-80bad9], details pre[data-v-80bad9] {
    max-width: 224px;
  }
}

p .show-data-uris[data-v-80bad9], details .show-data-uris[data-v-80bad9] {
  align-items: center;
  column-gap: 4px;
  margin: 0 0 4px;
  font-size: 14px;
  display: flex;
}

p .show-data-uris input[data-v-80bad9], details .show-data-uris input[data-v-80bad9] {
  margin: 0;
}

p table[data-v-80bad9], details table[data-v-80bad9] {
  width: 100%;
  border-spacing: 0;
}

p table thead th[data-v-80bad9], details table thead th[data-v-80bad9] {
  text-align: left;
  padding-right: 8px;
  font-size: 14px;
  font-weight: 500;
}

p table thead th[data-v-80bad9]:last-of-type, details table thead th[data-v-80bad9]:last-of-type {
  padding-right: 0;
}

p table tbody th[data-v-80bad9], details table tbody th[data-v-80bad9] {
  font-weight: normal;
}

p table tbody td[data-v-80bad9], details table tbody td[data-v-80bad9] {
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 8px;
  font-size: 14px;
  overflow: hidden;
}

p table tbody td[data-v-80bad9]:last-of-type, details table tbody td[data-v-80bad9]:last-of-type {
  padding-right: 0;
}

p table tbody pre[data-v-80bad9], details table tbody pre[data-v-80bad9] {
  margin: 4px 0 0;
}

.date-range[data-v-80bad9] {
  width: 100%;
  justify-content: space-between;
  margin: 0;
  font-size: 10px;
  display: flex;
}

.chart-wrapper[data-v-80bad9] {
  width: 300px;
}

@media (min-width: 1024px) {
  .chart-wrapper[data-v-80bad9] {
    width: 400px;
  }
}

.chart[data-v-80bad9] {
  width: 300px;
  height: 104px;
  cursor: crosshair;
  border-bottom: 1px solid #d5d5d5;
  border-left: 1px solid #d5d5d5;
  margin-top: 12px;
}

@media (min-width: 1024px) {
  .chart[data-v-80bad9] {
    width: 400px;
    height: 139px;
    margin-top: 8px;
  }
}

/*# sourceMappingURL=index.d45407b9.css.map */
