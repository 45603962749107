@use './style/design-tokens';

// Global styles
html,
body {
  height: 100vh;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: design-tokens.$colours-grey-200;
  color: design-tokens.$colours-grey-900;
}

a:link,
a:visited {
  color: design-tokens.$colours-blue-800;
  text-decoration-skip-ink: auto;
  transition: color 0.15s ease-in-out;
}

a:hover,
a:active {
  color: design-tokens.$colours-magenta-800;
}

::selection {
  background: rgba(design-tokens.$colours-secondary, 0.2);
}

// Header & intro
h1 {
  margin: 16px;
  font-weight: 100;
}

#intro {
  margin: 4px 0 16px;
  padding: 8px;
  font-size: 16px;
  font-weight: 300;

  @media (min-width: 1024px) {
    margin: 4px 0 32px;
    padding: 16px;
    font-size: 18px;
  }

  sup {
    vertical-align: text-top;

    a:link,
    a:visited {
      text-decoration: none;
    }
  }
}

// Output area, loading state
#app {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 0 100vh;

  .loading-spinner {
    opacity: 0;
    transition: all 0.3s ease-in-out;

    .loading & {
      opacity: 1;
    }
  }
}

// Scripts
// Need to specify here, as <small> is injected at runtime
.script h3 small {
  font-size: 1rem;
  line-height: 19px;
}

// Caveats
#caveats {
  width: 300px;
  padding: 8px 16px;
  margin-top: 32px;
  border-top: 1px solid design-tokens.$colours-grey-300;

  @media (min-width: 1024px) {
    width: 650px;
    padding: 16px 0;
    margin-bottom: 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 100;
  }

  .back {
    a {
      display: flex;
      align-items: center;
      column-gap: 4px;

      &::after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: url(/node_modules/@material-design-icons/svg/round/arrow_upward.svg);
        background-size: 16px;
        opacity: 0.666;
      }
    }
  }
}

// Footer
footer {
  width: 300px;
  padding: 8px 16px;
  border-top: 1px solid design-tokens.$colours-grey-300;
  font-size: 14px;
  color: design-tokens.$colours-grey-700;

  @media (min-width: 1024px) {
    width: 650px;
    padding: 16px 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-bottom: 4px;
  }
}

// Easter eggs
#script___inception {
  display: none;
}

.inception {
  .script[id^='script'] {
    display: none;
  }

  #script___inception {
    display: block;

    @media (min-width: 1024px) {
      display: flex;
    }
  }
}

.party {
  background-image: url(./images/loading.svg);
  background-attachment: fixed;
  background-position: center top;
  background-size: 128px;

  > * {
    background-color: rgba(design-tokens.$colours-grey-200, 0.2);
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 30px rgba(design-tokens.$colours-grey-900, 0.1);
    border-radius: 8px;
  }
}
