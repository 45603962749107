// Colours
$colours-blue-50: #e2f2fe;
$colours-blue-100: #baddfd;
$colours-blue-200: #8dc9fd;
$colours-blue-300: #5db4fc;
$colours-blue-400: #35a3fc;
$colours-blue-500: #0094fb;
$colours-blue-600: #0086ec;
$colours-blue-700: #0074d9;
$colours-blue-800: #0163c7;
$colours-blue-900: #0044a8;

$colours-magenta-50: #fbe5ff;
$colours-magenta-100: #f4befe;
$colours-magenta-200: #ed8fff;
$colours-magenta-300: #e558ff;
$colours-magenta-400: #dd00ff;
$colours-magenta-500: #d400fd;
$colours-magenta-600: #c000f8;
$colours-magenta-700: #a600f4;
$colours-magenta-800: #8e00f1;
$colours-magenta-900: #5800e7;

$colours-grey-50: #f8f8f8;
$colours-grey-100: #f0f0f0;
$colours-grey-200: #e6e6e6;
$colours-grey-300: #d5d5d5;
$colours-grey-400: #b0b0b0;
$colours-grey-500: #909090;
$colours-grey-600: #686868;
$colours-grey-700: #555555;
$colours-grey-800: #373737;
$colours-grey-900: #171717;

$colours-primary: $colours-blue-700;
$colours-secondary: $colours-magenta-400;
